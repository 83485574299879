import { createTheme } from '@mui/material/styles';

const tema = createTheme({
  palette: {
    primary: {
      main: '#ED1C24', // Rojo para enlaces, botones, etc.
    },
    secondary: {
      main: '#FFA94F', // Naranja para algunos fondos y botones
      dark: '#FF8C00', // Naranja más oscuro para hover
    },
    background: {
      default: '#F4F6F8', // Fondo general de la página
      paper: '#FFFFFF',   // Fondo blanco para componentes como tarjetas
    },
    text: {
      primary: '#000000', // Texto negro por defecto
      secondary: '#FFFFFF', // Texto blanco para fondo oscuro o resaltado
    },
  },
  typography: {
    fontFamily: 'Verdana, Arial, Helvetica, sans-serif', // Tipografía del CSS original
    h1: {
      fontFamily: 'Georgia, Times New Roman, Times, serif', // Título h1 con fuente serif
    },
    button: {
      fontSize: '14px',
      fontWeight: 'bold', // Botones más destacados
      textTransform: 'none', // No convertir los botones en mayúsculas
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFA94F', // Naranja para los botones
          color: '#FFFFFF', // Texto blanco en los botones
          border: '1px solid #cc6600', // Bordes de color naranja
          padding: '10px 20px', // Padding ajustado a la estética
          borderRadius: '5px', // Bordes redondeados
          '&:hover': {
            backgroundColor: '#FFB57B', // Naranja más claro cuando se pasa el ratón
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF', // Fondo blanco para los papeles
        },
      },
    },
  },
});

export default tema;
