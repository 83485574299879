import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import MyNavbar from './MyNavbar';

const Ejecuciones = () => {
  return (
    <Box>
      {/* <MyNavbar /> */}
      
      <Container 
        maxWidth="md"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          textAlign: 'center',
        }}
      >
        <Typography variant="h3" component="h1">
          Página de Ejecuciones
        </Typography>
      </Container>
    </Box>
  );
};

export default Ejecuciones;
