import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, Grid, AppBar, Toolbar } from '@mui/material';
import CardVistosa from './componentes/CardVistosa';

const Dashboard = () => {
  const [token, setToken] = useState('');

  useEffect(() => {
    // Obtén el token desde localStorage
    const authToken = localStorage.getItem('token');
    setToken(authToken);
  }, []);

  return (
    <Box>
      {/* Navbar personalizada */}
      {/* <MyNavbar /> */}

      <Container
        maxWidth="md"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          textAlign: 'center',
          paddingTop: 4,
        }}
      >
        <Grid container spacing={4} sx={{ padding: '20px' }}>
          <CardVistosa type="club" />
          <CardVistosa type="equipo" />
          <CardVistosa type="jugador" />
          <CardVistosa type="arbitro" />
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;

