import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, CircularProgress } from '@mui/material';
import api from '../servicios/apiService'
const CardVistosa = ({ type }) => {
  const [total,setTotal] = useState(null); // Estado para almacenar el número de elementos
  const [loading, setLoading] = useState(true); // Estado para manejar la carga de datos
  const [error, setError] = useState(null); // Estado para manejar posibles errores

  // Llamada API para obtener el número de elementos basados en el tipo
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null); // Reseteamos cualquier error previo
      let endpoint = '';

      // Definimos el endpoint de acuerdo al tipo
      if (type === 'club') {
        endpoint = '/clubs/count';
      } else if (type === 'equipo') {
        endpoint = '/equipos/count';
      } else if (type === 'jugador') {
        endpoint = '/jugadores/count';
      } else if (type === 'arbitro') {
        endpoint = '/arbitros/count';
      }

      try {
        const response = await api.get(endpoint); // Realizamos la llamada a la API
        setTotal(response.data.total); // Asumimos que la respuesta tiene un campo 'count'
      } catch (err) {
        setError('Error al obtener los datos'); // Manejo de error
      } finally {
        setLoading(false); // Terminamos la carga
      }
    };

    fetchData();
  }, [type]); // Dependencia del tipo para volver a ejecutar el useEffect si el tipo cambia

  return (
    <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Card
        sx={{
          width: '100%',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '12px',
          boxShadow: 3, // Sombra de la tarjeta
          backgroundColor: '#FFFFFF', // Fondo blanco de la tarjeta
        }}
      >
        <CardContent sx={{ textAlign: 'center' }}>
          {loading ? (
            <CircularProgress /> // Mostrar un spinner mientras carga
          ) : error ? (
            <Typography variant="body1" color="error">
              {error} {/* Mostrar mensaje de error si ocurre */}
            </Typography>
          ) : (
            <>
              <Typography variant="h6" sx={{ color: '#ED1C24', fontWeight: 'bold' }}>
                {type.charAt(0).toUpperCase() + type.slice(1)} {/* Capitaliza la primera letra */}
              </Typography>
              <Typography variant="h4" sx={{ color: '#000', fontWeight: 'bold' }}>
                {total}
              </Typography>
              <Typography variant="body2" sx={{ color: '#FFA94F' }}>
                Elementos registrados
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CardVistosa;
