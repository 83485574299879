import React, { useEffect } from 'react';
import { Container, Box, Typography } from '@mui/material';
import MyNavbar from './MyNavbar';

const Tareas = ({ titulo }) => {
  useEffect(() => {
    document.title = titulo; // Cambia el título de la pestaña del navegador
  }, [titulo]);

  return (
    <Box>
      {/* <MyNavbar /> */}

      <Container
        maxWidth="md"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          textAlign: 'center',
        }}
      >
        <Typography variant="h3" component="h1">
          Página de Tareas
        </Typography>
      </Container>
    </Box>
  );
};

export default Tareas;

