import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute'; // Ya creado previamente
import Layout from './Layout'; // Nuevo componente que incluye MyNavbar
import Dashboard from './Dashboard';
import Login from './Login';
import Tareas from './Tareas'; // Ejemplo de componente
import Ejecuciones from './Ejecuciones'; // Ejemplo de componente
import Presupuestos from './Presupuestos'; // Ejemplo de componente

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Ruta de Login (pública) */}
        <Route path="/login" element={<Login />} />
        
        {/* Rutas protegidas, dentro de Layout (que incluye MyNavbar) */}
        <Route element={<ProtectedRoute />}>
          <Route element={<Layout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/tareas" element={<Tareas />} />
            <Route path="/ejecuciones" element={<Ejecuciones />} />
            <Route path="/presupuestos" element={<Presupuestos />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default App;


