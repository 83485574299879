import React from 'react';
import MyNavbar from './MyNavbar';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div>
      {/* MyNavbar solo se renderiza en rutas protegidas */}
      <MyNavbar />
      {/* El Outlet renderiza las rutas protegidas */}
      <Outlet />
    </div>
  );
};

export default Layout;
