import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Card, CardContent, Typography, Grid, Box, useTheme } from '@mui/material';
import logo from './recursos/solobolos_logo3.gif'; // Asegúrate de reemplazar esto con la ruta correcta a tu logo

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const theme = useTheme(); // Accede al tema actual
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('isAuthenticated', 'true');
        navigate('/');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.message || 'Autenticación fallida',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Error en la solicitud',
      });
      console.error('Error:', error);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100vh',
        backgroundColor: theme.palette.background.default, // Fondo general según tema
        padding: 2,
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          width: '100%',
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <CardContent>
          <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
            <img
              src={logo}
              alt="Logo"
              style={{
                width: '80%', // Aseguramos que el logo no sea demasiado grande
                marginBottom: '20px',
              }}
            />
          </Box>

          <Typography variant="h5" align="center" gutterBottom>
            Iniciar sesión
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              label="Correo electrónico"
              type="email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{
                input: {
                  borderRadius: '5px',
                },
              }}
            />

            <TextField
              label="Contraseña"
              type="password"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              sx={{
                input: {
                  borderRadius: '5px',
                },
              }}
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                marginTop: 2,
                borderRadius: '5px',
                padding: '12px',
                backgroundColor: theme.palette.secondary.main, // Color secundario del tema
                '&:hover': {
                  backgroundColor: theme.palette.secondary.dark, // Hover color
                },
              }}
            >
              Iniciar sesión
            </Button>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Login;
