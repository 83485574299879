import React from 'react';
import { AppBar, Toolbar, Button, Container, Link, Avatar, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from './recursos/solobolos_logo3.gif'; // Asegúrate de reemplazar esto con la ruta correcta a tu logo

const MyNavbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Elimina el token del localStorage y redirige al login
    localStorage.removeItem('token');
    navigate('/login');
  };


  return (
    <AppBar position="static" color="primary">
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        {/* Logo alineado a la izquierda */}
        <Link href="/" color="inherit" underline="none">
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{ m:2 }}
          />
        </Link>

        {/* Enlaces de navegación a la derecha */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Link href="/tareas" color="inherit" underline="none" sx={{ marginRight: 2 }}>
            Tareas
          </Link>
          <Link href="/ejecuciones" color="inherit" underline="none" sx={{ marginRight: 2 }}>
            Ejecuciones
          </Link>
          <Link href="/presupuestos" color="inherit" underline="none" sx={{ marginRight: 2 }}>
            Presupuestos
          </Link>
          <Button color="inherit" variant="outlined" onClick={handleLogout}>
            Cerrar sesión
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MyNavbar;


