import React, { useEffect, useState } from 'react';
import MyNavbar from './MyNavbar';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Pagination } from '@mui/material';

const Presupuestos = () => {
  const [presupuestos, setPresupuestos] = useState([]); // Almacena todos los presupuestos
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const [searchText, setSearchText] = useState(''); // Texto de búsqueda
  const [error, setError] = useState(null);
  const perPage = 5; // Cantidad de registros por página

  useEffect(() => {
    const fetchPresupuestos = async () => {
      try {
        const token = localStorage.getItem('token'); // Obtener el token de localStorage
        const response = await fetch('http://api.nubig.es.local/api/presupuestos?id_empresa=25&id_licencia=1', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Incluir el token en los headers
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Error al obtener los presupuestos');
        }

        const data = await response.json();
        setPresupuestos(data); // Suponiendo que data es un array de presupuestos
      } catch (error) {
        setError(error.message);
      }
    };

    fetchPresupuestos();
  }, []);

  // Filtrar presupuestos según el texto de búsqueda
  const filteredPresupuestos = presupuestos.filter(presupuesto =>
    presupuesto.titulo.toLowerCase().includes(searchText.toLowerCase()) ||
    presupuesto.cliente.toLowerCase().includes(searchText.toLowerCase())
  );

  // Calcular el número total de páginas basado en los presupuestos filtrados
  const totalPages = Math.ceil(filteredPresupuestos.length / perPage);

  // Obtener los presupuestos de la página actual
  const currentPresupuestos = filteredPresupuestos.slice(
    (currentPage - 1) * perPage,
    currentPage * perPage
  );

  return (
    <div>
      {/* <MyNavbar /> */}
      <div className="container mt-5">
        <h1>Listado de Presupuestos</h1>
        {error && <p className="text-danger">{error}</p>}

        {/* Cuadro de búsqueda */}
        <div className="mb-3">
          <TextField 
            label="Buscar por título o cliente" 
            variant="outlined" 
            fullWidth 
            value={searchText} 
            onChange={(e) => setSearchText(e.target.value)} 
          />
        </div>

        {currentPresupuestos.length > 0 ? (
          <>
            <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <caption>Hostia como mola ssyto</caption>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Título</TableCell>
                    <TableCell>Cliente</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPresupuestos.map((presupuesto, index) => (
                    <TableRow
                      key={presupuesto.id}
                      sx={{
                        backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#ffffff',
                        '&:hover': { backgroundColor: '#e0e0e0' } // Efecto hover
                      }}
                    >
                      <TableCell>{presupuesto.id}</TableCell>
                      <TableCell>{presupuesto.titulo}</TableCell>
                      <TableCell>{presupuesto.cliente}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Paginación */}
            <div className="d-flex justify-content-center my-3">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(e, value) => setCurrentPage(value)}
                color="primary"
              />
            </div>
          </>
        ) : (
          <p>No hay presupuestos disponibles</p>
        )}
      </div>
    </div>
  );
};

export default Presupuestos;
