// src/services/apiService.js
import axios from 'axios';

// Configura la instancia de Axios
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Base URL desde las variables de entorno
  headers: {
    'Content-Type': 'application/json', // Tipo de contenido por defecto
  },
});

// Interceptor para incluir el token automáticamente en cada solicitud
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Obtener el token del almacenamiento local
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Incluir token en los headers
    }
    return config;
  },
  (error) => Promise.reject(error) // Manejar errores de configuración
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        // Redirigir al usuario al login si el token expira
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );
export default api;
